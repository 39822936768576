import * as React from 'react'
import { Assets, Colors } from '../assets';
import { Box } from './Box';
import { RouteComponentProps, withRouter } from 'react-router';
import { UserAPI } from '../api/endpoints/user';
import { getcurrentSearch } from '../util/search';
import { UserContextConsumer, UserContextType } from '../context/user';
import { ClubsetsButton } from './ClubsetsButton';
import { runInNewContext } from 'vm';

export interface ClubsetsHeaderProps extends RouteComponentProps {
    type: 'logo' | 'searchbar'
    profileImageUrl?: string
    onSearchValueChanged?: (value: string, submitBehaviour: boolean) => void
}

class ClubsetsHeader extends React.Component<ClubsetsHeaderProps, {
    showDropdown: boolean
    searchValue: string,
    subscribed: boolean
}> {
    static contextType = UserContextType
    context!: React.ContextType<typeof UserContextType>

    private searchTimer: NodeJS.Timeout;
    private dropDownToggle: HTMLDivElement | null;

    constructor(props: ClubsetsHeaderProps) {
        super(props);
        this.state = {
            subscribed: true,
            showDropdown: false,
            searchValue: getcurrentSearch(props.location) || '',
        }

        this.onSearchClicked = this.onSearchClicked.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.onArrowClicked = this.onArrowClicked.bind(this)
        this.onDocClick = this.onDocClick.bind(this)
    }

    componentDidUpdate(prevProps: ClubsetsHeaderProps) {
        if (getcurrentSearch(this.props.location) != getcurrentSearch(prevProps.location)) {
            this.setState({ searchValue: getcurrentSearch(this.props.location) || '' })
        }
    }

    componentDidMount() {
        document.addEventListener('click', this.onDocClick)
        UserAPI.getSubscription().then(() => {
            this.setState({ subscribed: true })
        }).catch(err => {
            this.setState({ subscribed: false })
        })
    }
    componentWillUnmount() {
        document.removeEventListener('click', this.onDocClick)
    }

    onDocClick(ev: MouseEvent) {
        if (!this.dropDownToggle!.contains(ev.target! as HTMLElement)) {
            this.setState({ showDropdown: false })
        }
    }

    render() {

        return (
            <UserContextConsumer>{(user) =>
                <div style={{ flex: 1 }}>
                    <div className={`header ${this.props.type === 'logo' ? 'logo-header' : 'searchbar-header'}`}>
                        {this.props.type === 'logo' ?
                            <img className="header-logo" src={this.context.theme == 'light' ? Assets.LOGO_DAY : Assets.LOGO} alt={'clubsets-logo'} onClick={() => this.props.history.push('/home')} />
                            :
                            <div className="header-searchbar">
                                <img src={Assets.ICON_LOUP} alt={'Search'} onClick={this.onSearchClicked} />
                                <input
                                    className="search-input"
                                    type="text"
                                    value={this.state.searchValue}
                                    placeholder={'Search'}
                                    onKeyDown={e => {
                                        if (e.key === 'Enter') {
                                            this.props.onSearchValueChanged && this.props.onSearchValueChanged(this.state.searchValue, true)
                                        }
                                    }}
                                    onChange={this.handleChange}
                                />
                            </div>
                        }
                        {this.state.subscribed == false && <ClubsetsButton style={{ marginLeft: 'auto' }} text='Start 14 Day Free Trial' onPress={() => {
                            this.props.history.push('/subscribe')
                        }} />}
                        <div className="right-content no-select">
                            <div ref={(ref) => this.dropDownToggle = ref} className="header-profile" onClick={this.onArrowClicked}>
                                <img className={`profile-image ${this.props.profileImageUrl ? 'circular' : 'square'}`} alt="profile-img"

                                    src={(this.props.profileImageUrl) || (user.theme === 'light' ? Assets.COLLIMATION_MARK_BLACK : Assets.COLLIMATION_MARK_WHITE)} />

                                <img className="dropdown-arrow" src={Assets.ARROW_DOWN} alt={'arrow-down'} />
                            </div>
                        </div>
                    </div>
                    {this.state.showDropdown &&
                        <Box className={`profile-dropdown${this.props.type === 'searchbar' ? ' top-lg' : ''}`} bgColor={Colors.DARK_GREY}>
                            <span onClick={() => this.props.history.push('/settings')}>{'Settings'}</span>
                            <span onClick={() => this.props.history.push('/billing')}>{'Billing'}</span>
                            <span onClick={async () => {
                                await UserAPI.logout()
                                this.props.history.push('/login')
                            }}>{'Logout'}</span>
                        </Box>
                    }
                </div>
            }</UserContextConsumer>
        )
    }

    private onSearchClicked() {
        this.props.onSearchValueChanged && this.props.onSearchValueChanged(this.state.searchValue, true)
    }

    private handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.searchTimer && clearTimeout(this.searchTimer)
        let val = event.target.value
        this.setState({ searchValue: val })
        this.searchTimer = setTimeout(() => {
            if (this.state.searchValue == val) {
                this.props.onSearchValueChanged && this.props.onSearchValueChanged(val, false)
            }
        }, 400)
    }


    private onArrowClicked() {
        if (this.state.showDropdown) {
            this.setState({ showDropdown: false })
        } else {
            this.setState({ showDropdown: true })
        }
    }
}

export default withRouter(ClubsetsHeader)