import React from 'react';
import ReactDOM from 'react-dom';
import './styles/all.scss';
import App from './App';
import { BrowserRouter, Switch, Route, Redirect, useHistory } from 'react-router-dom'
import * as serviceWorker from './serviceWorker';
import { Login } from './views/Login';
import { SignUp } from './views/SignUp';
import { Session } from './api/requests/request';
import { Environment } from './env';
import * as toastr from 'toastr'
import { ForgotPassword } from './views/ForgotPassword';
import Home from './pages/Home';
import TermsConditions from './pages/TermsConditions';
import PrivacyPolicy from './pages/PrivacyPolicy';

Session.setAPIURL(Environment.API)
const FallbackRouterApp: React.SFC = () => {
    const history = useHistory()
    Session.setUnauthorizedHandler(() => {
        history.push('/login')
    })
    return (
        <Switch>
            <Route path='/' exact component={Home} />
            <Route path='/login' component={Login} />
            <Route path='/forgot-password' component={ForgotPassword} />
            <Route path='/sign-up' component={SignUp} />
            {/*<Route path='/terms-and-conditions' component={TermsConditions} />*/}
            <Route path='/privacy-policy' component={PrivacyPolicy} />
            <Route path='/:page' component={App} />
            <Route render={() => {
                return <Redirect to='/' />
            }} />
        </Switch>
    )
}

ReactDOM.render(<BrowserRouter><FallbackRouterApp /></BrowserRouter>, document.getElementById('root'));
serviceWorker.unregister();

document.onclick = function () {
    document.querySelectorAll('[data-balloon-visible]').forEach((value) => {
        value.removeAttribute('data-balloon-visible')
    })
}