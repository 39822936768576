import * as React from 'react'
import { Assets } from '../assets';
import { Box } from '../Components/Box';
import { ClubsetsHeadline } from '../Components/ClubsetsHeadline';
import { translations } from '../translations';
import { ClubsetsTextInput } from '../Components/ClubsetsTextInput';
import { ClubsetsButton } from '../Components/ClubsetsButton';
import { Checkbox } from '@material-ui/core';
import isEmail from 'validator/lib/isEmail';
import { RouteComponentProps } from 'react-router';
import { LoginAPI } from '../api/endpoints/login';
import { getDevice } from '../api/util';
import { errorToString, APIErrorType } from '../api/errors';

export interface SignUpProps extends RouteComponentProps { }

export class SignUp extends React.Component<SignUpProps, {
    fullName: string
    email: string
    password: string
    agree: boolean
}> {

    constructor(props: any) {
        super(props);
        this.state = {
            fullName: '',
            email: '',
            password: '',
            agree: false
        }
    }

    private button: ClubsetsButton | null = null

    render() {

        return (
            <div className="flex full-height">
                <div className="login">
                    <div className="login-container">
                        <img src={Assets.LOGO} alt={'logo'} className="logo" onClick={() => {
                            this.props.history.push('/')
                        }} />
                        <Box className="login-box">
                            <ClubsetsHeadline
                                title={translations.JoinClubsets}
                                classname="headline"
                            />
                            <ClubsetsTextInput
                                caption={translations.FullName}
                                placeholder={translations.FullName}
                                onEnter={() => { this.signUp() }}
                                onChange={(name) => { this.setState({ fullName: name }) }}
                                onFocus={() => this.button && this.button.hideTooltip()}
                            />
                            <ClubsetsTextInput
                                caption={translations.Email}
                                placeholder={translations.EmailAddress}
                                onEnter={() => { this.signUp() }}
                                onChange={(email) => { this.setState({ email }) }}
                                onFocus={() => this.button && this.button.hideTooltip()}
                            />
                            <ClubsetsTextInput
                                caption={translations.Password}
                                placeholder={translations.Password}
                                type='password'
                                onEnter={() => { this.signUp() }}
                                onChange={(password) => { this.setState({ password }) }}
                                onFocus={() => this.button && this.button.hideTooltip()}
                            />
                            <div className="keep-me">
                                <Checkbox
                                    onChange={(event, checked) => {
                                        this.button && this.button.hideTooltip()
                                        this.setState({ agree: checked })
                                    }}
                                />
                                <span className="label left-space">{`${translations.AgreeTermsCond}`}
                                    <span className="link" onClick={() => {
                                        window.open('terms-and-conditions')
                                    }}>{translations.Terms}
                                    </span>
                                    <span className="label">{' & '}</span>
                                    <span className="link" onClick={(e) => {
                                        e.stopPropagation()
                                        window.open('privacy-policy')
                                    }}>{translations.PrivacyPolicy}
                                    </span>
                                </span>
                            </div>
                            <div className="login-button-container">
                                <ClubsetsButton
                                    ref={(ref) => this.button = ref}
                                    text={translations.SignUp}
                                    width={"100%"}
                                    onPress={() => { this.signUp() }}
                                />
                            </div>
                        </Box>
                        <div className="bottom-box">
                            <span className="text grey">{translations.AlreadyMember}
                                <span className="text orange" onClick={() => this.props.history.push('/login')}>{translations.LogIn}</span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    private async signUp() {
        this.button && this.button.loading(true)
        let error: string | undefined = undefined
        if (this.state.fullName.trim() === '') {
            error = translations.EnterName
        } else if (!isEmail(this.state.email) || this.state.password.trim() === '') {
            error = translations.EmailPasswordNotValid
        } else if (!this.state.agree) {
            error = translations.MustAgree
        }
        if (error) {
            if (this.button) {
                this.button.loading(false)
                this.button.showTooltip(error)
            }
        } else {
            LoginAPI.register(this.state.email, this.state.fullName, this.state.password, await getDevice()).then(() => {
                this.props.history.push('/home')
            }).catch(err => {
                this.button!.loading(false)
                switch (err.type) {
                    case APIErrorType.AlreadyExists:
                        this.button!.showTooltip('Email already exists. Try to login instead.')
                        break;
                    default:
                        this.button!.showTooltip(errorToString(err).join('\n'))
                }

            })
        }
    }
}