import * as React from 'react'
import { SideMenu } from '../Components/SideMenu';
import { UserContextConsumer, UserContextType } from '../context/user';
import { ListView } from '../Components/ListView';
import { AudioVideoProps } from '../api/models/media';
import shallowequal from 'shallowequal'
import { Assets } from '../assets';
import { RouteComponentProps, withRouter, Redirect } from 'react-router';
import ClubsetsHeader from '../Components/ClubsetsHeader';
import { Switch, Route } from 'react-router-dom';
import { MediaAPI, QuerySearchParameters, SortByQueryOptions, PaginationInfo } from '../api/endpoints/media';
import { Album } from '../api/models/album';
import { ClubsetsLoader } from '../Components/ClubsetsLoader';
import { getcurrentSearch } from '../util/search';
import { PlaybackManager } from '../player';

export interface DashboardProps extends AudioVideoProps, RouteComponentProps<{ category?: string }, any, any> {
    page: string
}
const pages = ['home', 'liked-songs', 'most-liked-songs', 'top-downloads']
const LIMIT = 40
class DashBoard extends React.Component<DashboardProps, {
    showMenu: boolean,
    apiFilter: QuerySearchParameters,
    albums?: Album[]
    pagingInfo?: PaginationInfo
    loadingListViewContent: boolean
    playerVisible: boolean
}> {
    private playbackListener: string;

    static contextType = UserContextType
    context!: React.ContextType<typeof UserContextType>

    constructor(props: DashboardProps) {
        super(props);
        this.state = {
            showMenu: false, loadingListViewContent: false, playerVisible: !!PlaybackManager.getInstance().isPlayerVisible,
            apiFilter: {
                keywords: getcurrentSearch(props.location)
            }
        }
    }
    componentDidMount() {
        this.load()
        this.playbackListener = PlaybackManager.getInstance().addPlaybackStateListener(() => {
            this.setState({ playerVisible: !!PlaybackManager.getInstance().isPlayerVisible })
        })
    }

    componentWillUnmount() {
        PlaybackManager.getInstance().removePlaybackStateListener(this.playbackListener)
    }

    componentDidUpdate(prevProps: DashboardProps, prvState: { apiFilter: QuerySearchParameters }) {
        const pagesNotEqual = this.props.page != prevProps.page || this.props.match.params.category != prevProps.match.params.category
        const stateNotEqual = !shallowequal(this.props.location.state, prevProps.location.state)
        if (pagesNotEqual || !shallowequal(this.state.apiFilter, prvState.apiFilter) || stateNotEqual) {
            if (pagesNotEqual || stateNotEqual) {
                let apiFilter = { keywords: getcurrentSearch(this.props.location) }
                if (this.props.location.state && this.props.location.state.apiFilter) {
                    apiFilter = Object.assign({}, apiFilter, this.props.location.state.apiFilter)
                }
                this.setState({ apiFilter }, () => {
                    this.load()
                })
            } else {
                this.load()
            }
        }
    }

    load(page: 'next' | 'prev' | 'first' | 'last' | number = 'first') {
        let showLoading = true
        let skip = 0;
        if (this.state.pagingInfo && page != 'first') {
            if (page == 'prev') {
                if (this.state.pagingInfo.page > 1)
                    skip = (this.state.pagingInfo.page - 2) * LIMIT
            } else if (page == 'next') {
                skip = this.state.pagingInfo.page * LIMIT
            } else if (page == 'last') {
                skip = (LIMIT * (Math.ceil(this.state.pagingInfo.total / LIMIT) - 1))
            } else if (!Number.isNaN(page)) {
                skip = LIMIT * (page - 1)
            }
        }
        this.getAPIFunc(skip).then(({ albums, paging }) => {
            showLoading = false
            this.setState({ albums, pagingInfo: paging, loadingListViewContent: false })
        }).catch(err => {
            setTimeout(() => {
                this.load(page)
            }, 2000)
        })
        setTimeout(() => {
            if (!showLoading) {
                return
            }
            this.setState({ loadingListViewContent: true, albums: [] })
        }, 350)
    }

    getTitle() {
        if (this.props.match.params.category) {
            return this.props.match.params.category
        }
        switch (this.props.page) {
            default:
                return this.props.page.replace(/-/g, ' ')
        }
    }

    getAPIFunc(skip: number = 0, limit = LIMIT) {
        if (this.props.match.params.category) {
            return MediaAPI.getAlbums({ ...this.state.apiFilter, category: this.props.match.params.category }, skip, limit)
        } else {
            switch (this.props.page) {
                case 'liked-songs':
                    return MediaAPI.getLikedAlbums(this.state.apiFilter, skip, limit)
                case 'most-liked-songs':
                    return MediaAPI.getMostLikedAlbums(this.state.apiFilter, skip, limit)
                case 'top-downloads':
                    return MediaAPI.getMostDownloadedAlbums(this.state.apiFilter, skip, limit)
                default:
                    return MediaAPI.getAlbums(this.state.apiFilter, skip, limit)
            }
        }
    }

    render() {
        const hideCategory = Boolean(this.props.match.params.category) && this.props.match.params.category != 'House'
        const categoryType = Boolean(this.props.match.params.category) && this.props.match.params.category == 'House' ? 'genre' : 'category'
        return (
            <UserContextConsumer>{(user) =>
                <>
                    <div className={`dashboard-container`}>
                        <div className="top-container">
                            <div className="left-container">
                                <div className="mark-container">
                                    <img className="clubsets-mark" src={user.theme === 'light' ? Assets.COLLIMATION_MARK_BLACK : Assets.COLLIMATION_MARK_WHITE} alt={'BREAKSRUS-collimation-mark'} />
                                </div>
                                <div className="burger-menu" onClick={() => this.showMenu()}>
                                    <div className="menu-line" />
                                    <div className="menu-line" />
                                    <div className="menu-line" />
                                </div>
                            </div>
                            <ClubsetsHeader
                                type={'searchbar'}
                                profileImageUrl={user.profile_image_thumbnail_url}
                                onSearchValueChanged={(search, submit) => {
                                    let set: string | undefined = search
                                    if (search != undefined && search.trim() == '') {
                                        set = undefined
                                    }
                                    submit = true
                                    if (submit || set == undefined) {
                                        if (set) {
                                            this.props.history.push(`/home?search=${encodeURIComponent(search)}`)
                                        } else {
                                            this.props.history.push(`${this.props.location.pathname}`)
                                        }
                                    }
                                    this.setState({ apiFilter: { ...this.state.apiFilter, keywords: set } })
                                }} />
                        </div>
                        <div className="bottom-container">
                            <div className={`side-menu-container${this.state.showMenu ? ' show' : ''}`}>
                                <SideMenu
                                    onNavItemPressed={(item) => {
                                        this.props.history.push(`/${item}`)
                                    }}
                                    onCategoryPressed={(item) => {
                                        this.props.history.push(`/category/${encodeURIComponent(item.name)}`)
                                    }} selectedItem={this.props.match.params.category || this.props.page}
                                />
                            </div>
                            <div className="content-container" style={this.state.playerVisible ? { paddingBottom: 141 } : {}}>
                                <div className="content-headline">
                                    <span style={{ textTransform: 'capitalize' }}>{this.getTitle()}</span>
                                </div>
                                {this.state.albums == undefined && <ClubsetsLoader />}
                                {this.state.albums && <ListView
                                    chordType={this.context.chord_setting}
                                    categoryColumnType={categoryType}
                                    sorting={this.state.apiFilter.sort_by}
                                    context={this.getTitle()}
                                    loading={this.state.loadingListViewContent}
                                    onLoadMore={(page: 'next' | 'prev' | 'first' | 'last' | number) => {
                                        this.load(page)
                                    }} onBPMClicked={(bpm) => {
                                        this.props.history.push(`/home`, { apiFilter: { bpm_max: (bpm).toString(), bpm_min: (bpm).toString() } })
                                    }} onExclusiveClick={() => {
                                        this.props.history.push(`/home`, { apiFilter: { exclusive: true } })
                                    }} onCategoryClicked={(item) => {
                                        this.props.history.push(`/category/${encodeURIComponent(item.name)}`)
                                    }} onKeyClicked={(key) => {
                                        this.props.history.push(`/home`, { apiFilter: { key: key } })
                                    }} onDateClicked={(date) => {
                                        this.props.history.push(`/home`, { apiFilter: { date: date } })
                                    }} onArtistClick={(artist) => {
                                        this.props.history.push(`/home`, { apiFilter: { artist: artist } })
                                    }} onSortingChange={(type) => {
                                        this.setState({ apiFilter: { ...this.state.apiFilter, sort_by: type } })
                                    }}
                                    pagingInfo={this.state.pagingInfo} onAlbumSelected
                                    albums={this.state.albums} {...this.props}
                                    hideColumns={{ category: hideCategory }} />}
                            </div>
                        </div>
                    </div>
                </>
            }</UserContextConsumer>
        )
    }

    private showMenu() {
        if (this.state.showMenu) {
            this.setState({ showMenu: false })
        } else {
            this.setState({ showMenu: true })
        }
    }
}

export default withRouter(class DashbaordRouted extends React.Component<AudioVideoProps & RouteComponentProps<{ page: string }>> {

    public render() { // ToDo
        return <Switch>
            <Route path={'/category/:category'} render={(p) => {
                return <DashBoard {...this.props} {...p} page={this.props.match.params.page} />
            }} />
            <Route path={pages.map(p => '/' + p)} render={(p) => {
                return <DashBoard {...this.props} {...p} page={this.props.match.params.page} />
            }} />
            <Route render={() => {
                return <Redirect to='/home' />
            }} />
        </Switch>

    }
})